import { cookie } from "./cookie";

export function parseJwt(token = cookie("token")) {
  var base64Url = token?.split(".")[1];
  if (base64Url === undefined) {
    return cookie("clear");
  }
  // console.log(base64Url);
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c?.charCodeAt(0)?.toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload)??null;
}

export const ROLECONTROL = (role) => {
  return parseJwt()?.roles?.includes(role) ? true : false;
};
