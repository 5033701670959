import { configureStore } from "@reduxjs/toolkit";
import sidebarToggle from "features/sidebarToggleSlice";
import warehouse from "features/warehouse";
import customers from "features/customers";
import profile from "features/profile";
import settings from "features/settings";
import companies from "features/companies";
import parcels from "features/parcels";
import branches from "features/branches";
import destinationPoints from "features/destinationPoints";
import statuses from "features/statuses";
import shippings from "features/shippings";
import users from "features/users";
import auth from "features/auth";

const store = configureStore({
  reducer: {
    sidebarToggle,
    warehouse,
    customers,
    profile,
    settings,
    companies,
    branches,
    parcels,
    destinationPoints,
    statuses,
    shippings,
    users,
    auth,
  },
});

export const state = store.getState();

export default store;
