const { api } = require("apis/axios");

export const download = (url, fileName) => {
  api
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data]);

      const href = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.href = href;
      a.click();
      a.href = "";
    });
};
