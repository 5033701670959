import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
  },
});

export const { setAccessToken } = auth.actions;

export default auth.reducer;
