import { Button, Form, Input } from "antd";
import moment from "moment/moment";
import { useState } from "react";
import { getParcelStatus } from "requests/public";
import { GiCheckMark } from "react-icons/gi";
import { BsClockHistory } from "react-icons/bs";

const Tackparcel = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [customer, setCustomer] = useState(false);

  const [form] = Form.useForm();
  const handleParcelSearch = async (values) => {
    setLoading(true);
    try {
      const resStatus = await getParcelStatus(values.parcelCode);
      setStatus(resStatus.data.status);
      setCustomer(resStatus.data.customer);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="min-h-[80vh] flex  flex-col items-center">
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleParcelSearch}
      >
        <div className="grid grid-cols-1  max-w-xl w-full">
          <Form.Item
            label={<div className="text-black">Bağlama nömrəsi</div>}
            name="parcelCode"
            rules={[
              {
                required: true,
                message: "Bağlama nömrəsi boş buraxılmamalıdır!",
              },
            ]}
          >
            <Input
              size="large"
              className="!w-full"
              placeholder="Bağlama nömrəsi daxil edin"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
              block
            >
              Axtar
            </Button>
          </Form.Item>
        </div>
      </Form>
      {customer && (
        <div>
          <div className="flex items-center justify-between">
            <div className="text-xl font-bold text-gray-700">
              {customer?.name} {customer?.surname}
            </div>
            <div className="text-xl font-bold text-gray-700 pl-4">
              {customer?.phone}
            </div>
          </div>
        </div>
      )}
      {status && (
        <div className="max-w-xl w-full p-4">
          {/* <div className="flex gap-x-4 items-center">
            <div className="status p-4 rounded-full mb-2 bg-green-600 h-10 w-10 relative after:absolute after:h-3 after:-bottom-2 after:left-5 after:w-1 after:-translate-x-0.5 after:bg-green-600"></div>
            <div className="text-xl">{status?.from?.name}</div>
          </div> */}
          {status?.map((item, index) => (
            <div className="flex gap-x-4 items-center" key={index}>
              <div
                className={`status shrink-0 flex justify-center items-center rounded-full mb-2 ${
                  item?.date ? "bg-green-600" : "bg-green-200"
                } h-10 w-10 relative after:absolute after:h-3 after:-bottom-2 after:left-5 ${
                  index + 1 === status.length ? "after:w-0" : "after:w-1"
                } after:-translate-x-0.5 ${
                  item?.date ? "after:bg-green-600" : "after:bg-green-200"
                }`}
              >
                {item?.date ? (
                  <GiCheckMark className="text-white text-base" />
                ) : (
                  <BsClockHistory className="text-white text-base" />
                )}
              </div>
              <div className="text-base">
                <div>{item.name}</div>
                <div className="font-thin text-xs italic">
                  {item?.date
                    ? moment(item.date).format("DD.MM.YYYY HH:mm")
                    : ""}
                </div>
              </div>
            </div>
          ))}

          {/* <div className="flex gap-x-4 items-center">
            <div className="status p-4 rounded-full mb-2 bg-green-200 h-10 w-10 "></div>
            <div className="text-xl">{status?.to?.name}</div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Tackparcel;
