import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSetting, updateSetting } from "requests/settings";

const initialState = {
  loading: false,
  updating: false,
  settings: false
  // {
  //   invoiceWidth: "",
  //   additionalInfo: "",
  //   allowAfterStockOut: false,
  //   customerInfoInInvoice: false,
  //   vatValueInInvoice: false,
  //   showProductImageInSalePage: false,
  // },
};

export const getSettingInfo = createAsyncThunk(
  "getSettingInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const response = await getSetting();
    return response.data;
  }
);
export const updateSettingInfo = createAsyncThunk(
  "updateSettingInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const response = await updateSetting(data);
    return response.data;
  }
);

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettingInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSettingInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.settings = action.payload;
    });
    builder.addCase(getSettingInfo.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateSettingInfo.pending, (state, action) => {
      state.updating = true;
    });
    builder.addCase(updateSettingInfo.fulfilled, (state, action) => {
      state.updating = false;
      state.settings = action.payload;
    });
    builder.addCase(updateSettingInfo.rejected, (state, action) => {
      state.updating = false;
    });
  },
});

// export const { toggle } = settings.actions;

export default settings.reducer;
