import { api } from "apis/axios";
import { download } from "./download";




export const getWareHouse = async (data) => {
  return await api.get(
    `/warehouses?search=${data?.search ?? ""}&page=${data?.page ?? 1}&limit=${data?.limit ?? 10}`
  );
};




export const setWarehouse = async (data) => {
  return await api.post("/warehouses", data);
};
export const deleteWarehouse = async (id) => {
  return await api.delete(`/warehouses/${id}`);
};
export const setProduct = async (data) => {
  return await api.post("/products", data);
};
export const getProduct = async (data) => {
  return await api.get(
    `/products?search=${data?.search ?? ""}&category=${data?.category ?? ""}&page=${data?.page ?? 1}&limit=${data?.limit ?? 10}`
  );
};
export const getSingleProduct = async (data) => {
  return await api.get(`/products/${data?.code}`);
};
export const deleteProduct = async (id) => {
  return await api.delete(`/products/${id}`);
};
export const deleteManyProduct = async (products) => {
  return await api.put(`/products`,{items:products});
};
export const exportProducts = async (data) => {
  download("/products/export", "products.xlsx");
};
export const importProducts = async (data) => {
  return await api.post(`/products/import`,data);
};

