import { api } from "apis/axios";
import { download } from "./download";

export const getCompanies = async (data) => {
  return await api.get(
    `/companies?search=${data?.search ?? ""}&barcode=${
      data?.barcode ?? ""
    }&page=${data?.page ?? 1}&limit=${data?.limit ?? 10}`
  );
};
export const setCompany = async (data) => {
  return await api.post(`/companies`, data);
};
export const deleteCompanies = async (id) => {
  return await api.delete(`/companies/${id}`);
};
export const getSingleCompanies = async (id) => {
  return await api.get(`/companies/${id}`);
};

export const updateCompany = async ({ id, data }) => {
  return await api.put(`/companies/${id}`, data);
};
export const createCompanyrPayment = async (data) => {
  return await api.post(`/companies/pay`, data);
};
export const getCompanyPayment = async ({
  company = "",
  page = 1,
  limit = 10,
}) => {
  return await api.get(
    `/companies/payments?company=${company}&page=${page}&limit=${limit}`
  );
};
export const exportCompanies = async (data) => {
  download("/companies/export", "companies.xlsx");
};
