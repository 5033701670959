import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapse: false,
  minify: true,
};

export const sidebarToggleSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    toggle: (state) => {
      state.collapse = !state.collapse;
    },
    setMinify: (state) => {
      state.minify = !state.minify;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggle, setMinify } = sidebarToggleSlice.actions;

export default sidebarToggleSlice.reducer;
