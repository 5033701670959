import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomers } from "requests/customers";

const initialState = {
  loading: false,
  error: "",
  customers: [],
  totalCustomers: 0,
  totalCustomersDebt: 0,
  segment:"inSenderWarehouse"
};

export const getCustomersList = createAsyncThunk(
  "getCustomersList",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCustomers(data);

      return response.data;
    } catch (err) {
      console.log(err, "err");
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const customers = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setSegment: (state, action) => {
      state.segment = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomersList.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCustomersList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.customers = action.payload.customers;
      state.totalCustomers = action.payload.totalCustomers;
      state.totalCustomersDebt = action.payload.totalCustomersDebt;
    });
    builder.addCase(getCustomersList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
    });
  },
});

export const { setSegment } = customers.actions;

export default customers.reducer;
