import React from "react";

const ProhibitedItems = () => {
  return (
    <div>
      <div>
        <img
          className="h-[300px] w-full object-cover "
          src={require("assets/images/about.jpg")}
          alt=""
        />
      </div>
      <div className="container m-auto">
      <div className="text-base">
                <div className="text-center text-2xl font-bold py-4">
                  <h1 className="text-2xl md:text-4xl font-bold">
                    Qadağan olunan məhsullar
                  </h1>
                </div>

                <div className="bg-white rounded-md p-8 flex flex-col gap-2">
                  <div>
                    • Çılçıraq və çərçivə ( Qablaşdırılması düzgün olmadıqda )
                  </div>
                  <div>
                    • Hər cür silah növləri, döyüş sursatı, hərbi təyinatlı hər
                    cür əşyalar, partlayıcı maddələr, hərbi texnika.
                  </div>
                  <div>
                    • Təhlükəli maddələr, eləcə də radioaktiv maddələr və
                    onların hazırlanması texnologiyasına aid materiallar;
                  </div>
                  <div>
                    • Narkotik və psixotrop maddələr, onlardan istifadə üçün
                    ləvazimat, həmçinin hazırlanması texnologiyasına aid
                    materiallar.
                  </div>
                  <div>
                    • Pornoqrafik əşyalar, əxlaq pozğunluğunu, zorakılığını,
                    terrorizmi təbliğ və reklam edən materiallar.
                  </div>
                  <div>• Canlı heyvanlar,bitki və toxumlar.</div>
                  <div>
                    • Gizli kameralar, xüsusi məxfi rabitə vasitələri, onların
                    şifrləri və istismarı haqqında ədəbiyyat.
                  </div>
                  <div>
                    • Müdafiə, Milli Təhlükəsizlik və Daxili İşlər
                    nazirliklərinin operativ istintaq fəaliyyəti üzrə
                    informasiya sistemləri, sənədləri və arxivləri.
                  </div>
                  <div>• Akkumulyatorlar;</div>
                  <div>• Qida məhsulları</div>
                  <div>• Dini kitablar</div>
                  <div>• Amortizatorlar;</div>
                  <div>• Mühərriklər;</div>
                  <div>• Hover Board skuterlər;</div>
                  <div>
                    • Maşın yağları və maşın yağının olduğu bütün mallar.
                  </div>
                  <div>
                    • Hava nəqliyyatı vasitəsilə daşınması qadağan olunan
                    əşyalar.
                  </div>
                  <div>
                    • Azərbaycan Respublikasının ərazisinə idxalı qadağan olunan
                    əşyalar.
                  </div>
                  <div>
                    • Türkiyədən litium batareyalar və litium batareyalara sahib
                    texnikalar.
                  </div>
                  <div className="text-lg font-extrabold">
                    *Odlu silah, silah hissələri və ya aksesuarları, optik
                    nişan, optik binokl və döyüş üçün nəzərdə tutulan əşyaların
                    ixracatına icazə yoxdur.
                  </div>
                </div>
              </div>
      </div>
    </div>
  );
};

export default ProhibitedItems;
