import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";  
import { getStatuses } from "../requests/statuses";

const initialState = {
  loading: false,
  statuses: {
    items: [],
  },
};

export const getStatusesInfo = createAsyncThunk(
  "getStatusesInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getStatuses(data);
      return response.data;
    } catch (err) {}
  }
);

export const destinationPoints = createSlice({
  name: "destinationPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatusesInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStatusesInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.statuses = action.payload;
    });
    builder.addCase(getStatusesInfo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// export const {  } = destinationPoints.actions;

export default destinationPoints.reducer;
