import { api } from "apis/axios";
import { download } from "./download";

export const getShippings = async (data) => {
  return await api.get(
    `/shippings?search=${data?.search ?? ""}&page=${data?.page ?? 1}&limit=${
      data?.limit ?? 10
    }`
  );
};
export const setShippings = async (data) => {
  return await api.post(`/shippings`, data);
};
export const deleteShippings = async (id) => {
  return await api.delete(`/shippings/${id}`);
};
export const getSingleShippings = async (id) => {
  return await api.get(`/shippings/${id}`);
};

export const updateShipping = async ({ id, data }) => {
  return await api.put(`/shippings/${id}`, data);
};
export const updateStatus = async ({ id, data }) => {
  return await api.put(`/shippings/update/status/${id}`, data);
};
export const startShipping = async ({ id }) => {
  return await api.put(`/shippings/start/${id}`);
};
export const deliveredShipping = async ({ id }) => {
  return await api.put(`/shippings/delivered/${id}`);
};
export const checkParcel = async ({ id, data }) => {
  return await api.put(`/shippings/check/parcel/${id}`, data);
};
// export const updateParcelPayment = async (data) => {
//   return await api.post(`/shippings/pay`, data);
// };
// export const getParcelPayment = async (data) => {
//   return await api.get(`/shippings/payments?parcel=${data.Parcel}`);
// };
export const exportShippings = async (data) => {
  download("/shippings/export", "shippings.xlsx");
};
