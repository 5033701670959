import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBranches } from "requests/branches";

const initialState = {
  loading: false,
  branches: {
    items: [],
  },
};

export const getBranchesInfo = createAsyncThunk(
  "getBranchesInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBranches(data);
      return response.data;
    } catch (err) {}
  }
);

export const branches = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBranchesInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBranchesInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.branches = action.payload;
    });
    builder.addCase(getBranchesInfo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// export const {  } = branches.actions;

export default branches.reducer;
