export function cookie(param) {
    let cookies = document.cookie.split(";")
    if (param === "clear") {
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        }
    } else {
        let data = ""
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].split("=")[0].trim() === param) {
                data = cookies[i].split("=")[1]
                break
            }
        }
        return data
    }
}
