import { api } from "apis/axios";

export const getUsers = async (data) => {
  return await api.get(
    `/user/list?search=${data?.search ?? ""}&page=${data?.page ?? 1}&limit=${
      data?.limit ?? 10
    }`
  );
};
export const getUser = async (data) => {
  return await api.get(`/user/${data?.id ?? ""}`);
};
export const setUsers = async (data) => {
  return await api.post(`/user`, data);
};
export const deleteUsers = async (id) => {
  return await api.delete(`/user/${id}`);
};
export const updateUsers = async ({ id, data }) => {
  return await api.put(`/user/update/${id}`, data);
};
 