import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategory } from "requests/category";
import { getUnits } from "requests/units";
import { getWareHouse, setProduct } from "requests/warehouse";

const initialState = {
  loading: false,
  loadingWarehouse: false,
  error: "",
  products: [],
  warehouse: [],
  total: 0,
  success: false,
  categories: [],
  units: [],
  segment: "",
};

export const setProductInfo = createAsyncThunk(
  "setProductInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await setProduct(data);

      return response.data;
    } catch (err) {
      console.log(err, "err");
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const getWarehouseInfo = createAsyncThunk(
  "getWarehouseInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWareHouse(data);
      return response.data;
    } catch (err) {}
  }
);
export const getUnitsInfo = createAsyncThunk(
  "getUnitsInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUnits(data);

      return response.data;
    } catch (err) {
      console.log(err, "err");
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const getCategoryInfo = createAsyncThunk(
  "getCategoryInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCategory(data);

      return response.data;
    } catch (err) {
      console.log(err, "err");
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const warehouse = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setSegment: (state, action) => {
      state.segment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWarehouseInfo.pending, (state, action) => {
      state.loadingWarehouse = true;
      state.error = "";
    });
    builder.addCase(getWarehouseInfo.fulfilled, (state, action) => {
      state.loadingWarehouse = false;
      state.warehouse = action.payload.items;
      state.total = action.payload?.total ?? 0;
    });
    builder.addCase(getWarehouseInfo.rejected, (state, action) => {
      state.loadingWarehouse = false;
      state.error = "Server not response";
    });

    builder.addCase(setProductInfo.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.success = false;
    });
    builder.addCase(setProductInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = true;
    });
    builder.addCase(setProductInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
      state.success = false;
    });

    builder.addCase(getCategoryInfo.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.segment = "";
    });
    builder.addCase(getCategoryInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.categories = action.payload;
      state.segment = action.payload[0]._id;
    });
    builder.addCase(getCategoryInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
      state.segment = "";
    });
    builder.addCase(getUnitsInfo.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUnitsInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.units = action.payload;
    });
    builder.addCase(getUnitsInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
    });
  },
});

export const { setSegment } = warehouse.actions;

export default warehouse.reducer;
