import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getShippings } from "requests/shippings";

const initialState = {
  loading: false,
  error: "",
  shippings: [],
  total: 0,
};

export const getShippingsList = createAsyncThunk(
  "getShippingsList",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getShippings(data);

      return response.data;
    } catch (err) {}
  }
);

export const shippings = createSlice({
  name: "shippings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShippingsList.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getShippingsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.shippings = action.payload?.items;
      state.total = action.payload?.total;
    });
    builder.addCase(getShippingsList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
    });
  },
});

export const { toggle } = shippings.actions;

export default shippings.reducer;
