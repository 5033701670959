import { api } from "apis/axios";

export const getStatuses = async (data) => {
  return await api.get(`/statuses?search=${data?.search ?? ""}`);
};
export const getStatus = async (data) => {
  return await api.get(`/statuses/from/${data?.from}/to/${data?.to}`);
};
export const setStatuses = async (data) => {
  return await api.post(`/statuses`,data);
};
export const deleteStatuses = async (id) => {
  return await api.delete(`/statuses/${id}`);
};
export const updateStatuses = async ({id,data}) => {
  return await api.put(`/statuses/${id}`,data);
};
