import { api } from "apis/axios";

export const getDestinationPoints = async (data) => {
  return await api.get(`/destinationPoints?search=${data?.search ?? ""}`);
};
export const setDestinationPoints = async (data) => {
  return await api.post(`/destinationPoints`,data);
};
export const deleteDestinationPoints = async (id) => {
  return await api.delete(`/destinationPoints/${id}`);
};
export const updateDestinationPoints = async ({id,data}) => {
  return await api.put(`/destinationPoints/${id}`,data);
};
