import { api } from "apis/axios";

export const getUnits = async (data) => {
    return await api.get(`/units?search=${data?.search??""}`);
  };
export const setUnits = async (data) => {
    return await api.post(`/units`,data);
  };

  export const deleteUnit = async (id) => {
    return await api.delete(`/units/${id}`);
  };

  export const updateUnit = async ({id,data}) => {
    return await api.put(`/units/${id}`,data);
  };