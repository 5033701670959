import Home from "pages/Home";
import Login from "pages/Login";
// import Register from "pages/Register";
// import EInvoice from "pages/EInvoice";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Tackparcel from "pages/Tackparcel";
import StaticLayout from "pages/StaticLayout";
import About from "pages/About";
import ProhibitedItems from "pages/ProhibitedItems";

const Layout = lazy(() => import("containers/Layout"));

export const loading = (
  <div className="  h-screen flex justify-center items-center">
    <h1>Loading...</h1>
  </div>
);

function App() {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/" element={<StaticLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/prohibited" element={<ProhibitedItems />} />
          <Route path="/about" element={<About />} />
          <Route path="/tackparcel" element={<Tackparcel />} />
        </Route>
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/verify" element={<TwoFA />} /> */}
        {/* <Route path="/reset-password/:uid/:token" element={<ResetPassword />} /> */}
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route path="/e-invoice/:key" element={<EInvoice />} /> */}
        <Route path="/*" element={<Layout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
