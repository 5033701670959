import { notification } from "antd";
import { cookie } from "utils/cookie";

export const logout = () => {
  localStorage.clear();
  cookie("clear");
  window.location.assign("/login");
};




export const errorHandler = (error, info) => {
  if(error.response){
    if(error.response.status === 401){
      logout();
    }else{
      notification.error({
        message: "Error",
        description: error.response.data.error || error.response.data.message
      });
    }

  }
}