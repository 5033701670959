import { api } from "apis/axios";
import { download } from "./download";

export const getCustomers = async (data) => {
  return await api.get(
    `/customers?search=${data?.search ?? ""}&barcode=${
      data?.barcode ?? ""
    }&page=${data?.page ?? 1}&limit=${data?.limit ?? 10}`
  );
};
export const setCustomers = async (data) => {
  return await api.post(`/customers`, data);
};
export const deleteCustomers = async (id) => {
  return await api.delete(`/customers/${id}`);
};
export const getSingleCustomers = async (id) => {
  return await api.get(`/customers/${id}`);
};
export const getParcelForCustomers = async (query) => {
  return await api.get(
    `/customers/parcels?sender=${query.sender ?? ""}&receiver=${
      query.receiver ?? ""
    }&filter=${query.filter}&page=${query.page ?? ""}&limit=${query.limit ?? ""}`
  );
};

export const updateCustomer = async ({ id, data }) => {
  return await api.put(`/customers/${id}`, data);
};
export const updateCustomerPayment = async (data) => {
  return await api.post(`/customers/pay`, data);
};
export const getCustomerPayment = async (data) => {
  return await api.get(`/customers/payments?customer=${data.customer}`);
};
export const exportCustomers = async (data) => {
  download("/customers/export", "customers.xlsx");
};
