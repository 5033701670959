import { apiPublic } from "apis/axios";
// import { data } from "autoprefixer";

export const getBranches = async () => {
  return await apiPublic.get("/public/branches");
};
export const getServices = async () => {
  return await apiPublic.get("/public/services");
};
export const getServicesSlider = async () => {
  return await apiPublic.get("/public/servicesSlider");
};
export const getParcelStatus = async (id) => {
  return await apiPublic.get("/parcels/status/" + id);
};
