import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getParcels } from "requests/parcels";

const initialState = {
  loading: false,
  error: "",
  parcels: [],
  total: 0,
};

export const getParcelsList = createAsyncThunk(
  "getParcelsList",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getParcels(data);

      return response.data;
    } catch (err) {}
  }
);

export const parcels = createSlice({
  name: "parcels",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParcelsList.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getParcelsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.parcels = action.payload.items;
      state.total = action.payload.total;
    });
    builder.addCase(getParcelsList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
    });
  },
});

export const { toggle } = parcels.actions;

export default parcels.reducer;
