import React from "react";

const About = () => {
  return (
    <div>
      <div>
        <img
          className="h-[300px] w-full object-cover "
          src={require("assets/images/about.jpg")}
          alt=""
        />
      </div>
      <div className="container m-auto">
        <div className="">
          <div className="text-center text-2xl font-bold p-4">
            <h1 className="text-2xl md:text-4xl font-bold ">Haqqımızda</h1>
          </div>
          <div className="px-2">
            <p>
              "Şuşa Cargo" 2010-cu ildən Azərbaycan bazarında fəaliyyət göstərən
              logistika şirkətidir. Şirkət Azerbaycan qanunvericiliyinə əsasən
              qadagan edilməmiş hər növ yüklərin xarici ölkələrdən Azərbaycan'a,
              Azərbaycandan ise xarici ölkələrə daşınmasında və Azərbaycan
              daxilində qapıya çatdirilmasinda fəaliyyət göstərir. "Şuşa Cargo"
              hal-hazirda müştərilərinə elektron ticarət logistikasi, anbar,
              havayolu kargo, dəniz daşımacılığı, tarixi və sənəd əsərlərinin,
              təhlükəli maddələrin və temperatur həssaslığı olan bağlamaların
              təhlükəsiz şəkildə daşınmasına ve eyni şəkildə qapıda təslim ilə
              yanaşı çarter xidmətlərini təklif edir. "Şuşa Cargo" həmçinin
              Beynəlxalq Hava Nəqliyyat Assosiasiyasının(İATA) Azərbaycandaki
              üzvüdür.
            </p>
            <h1 className="font-extrabold text-xl">Anbar xidmətləri.</h1>
            <p>
              "Şuşa Cargo" logistika şirkəti Türkiye, Rusiya, Çin, İran,
              Almaniya ve Afrika ölkələrində alış-veriş edən şəxslərə bu
              ölkələrdə anbar xidməti göstərməklə yanaşı həmçinində bu anbarlara
              elektron ticarət zamanı qanunla qadağan edilməmiş hər növ bağlama
              göndərə bilər.
            </p>
            <h1 className="font-extrabold text-xl">Havayolu kargo xidməti.</h1>
            <p>
              "Şuşa Cargo" logistika şirkəti olaraq Azərbaycan qanunlarına uyğun
              olaraq istənilən növ ve çəkidəki yükləri hava yolu ilə dünyanın
              istənilən ölkəsinə göndərmək və müxtəlif növ baglamaları ofis ve
              anbarlarımızdan təhvil alıb istənilən ölkəyə ən qısa müddətdə və
              ən təhlükəsiz şəkildə çatdırırıq. Beləki biz bağlamaların
              təhkükəsiz daşınmasına tam zəmanət veririk. Bizim başlıca
              öncəliyimiz müştəri məmnuniyyətini təmin etməkdir.
            </p>
            <h1 className="font-extrabold text-xl">Dəniz daşımaçılığı</h1>
            <p>
              Dəniz daşımaçılığı ən çox üstünlük verilən nəqliyyat növüdür və
              biz bu sahədədə çəkisindən asılı olmayaraq hər növ yüklərinizi
              daşıyırıq. Hava və quru yolları daşımaçılığından bir neçə dəfə
              ucuz olduğu üçün ağır yüklərin daşınmasında da ən sərfəli vasitə
              olan Dəniz daşımaçılığında yüklərin tam təhlükəsizliyinə və
              vaxtında çatdırılmasına təminat veririk. Bu xidmətlərimizlə
              müştərilərimiz ağır yüklü konteynerlərin və hər növ məhsulların
              daşinmasinda əlavə xərclərdən xilas olacaq və yüklərin
              təhlükəsizliyinə əmin olacaq. "Şuşa Cargo" müxtəlif yüklərin bir
              limandan digərinə və ya bir limandan dənizə çıxışı olmayan digər
              nöqtəyə çatdırılmasını təmin edir.
            </p>
            <h1 className="font-extrabold text-xl">Tarixi və sənəd əsərlərinin daşınması</h1>
            <p>
              Müştərilərimiz üçün heç bir Maddi məbləğlə ifadə edilməyən
              əsərlərin həmçinin tarixi mənəvi əhəmiyyəti olan yüklərin
              daşınması "Şuşa Cargo" təhlükəsizliyi ilə həyata keçirilir. Bu
              sektordakı geniş şəbəkənin və öz sahələrində 1 nömrə olan
              beynəlxalq həmkarlarımız sayəsində qorunma ehtiyacı olan yüklərin
              tam təhlükəsizliyinə zəmanət verərək çatdırılır. Təhlükəli
              yüklərin daşınması üzrə Beynəlxalq Hava Nəqliyyat
              Assosiasiyasının(İATA) sertifikatlarına sahib olan peşakar
              kollektivlərdən ibarət "Şuşa Cargo" logistika şirkəti tarixi və
              sərgi əhəmiyyətli həmcinin müştərilərimiz üçün özəl əhəmiyyəti
              olan yüklərin çatdırılmasına zəmanət verərək müştərilərimiz də
              əminlik və rahatlıq yaradır. Beynəlxalq standartlara əməl edən
              peşəkar əməkdaşlarımız xüsusi və özəl əhəmiyyətli yüklərin yüksək
              və təhlükəsiz səviyyədə paketlənməsinə və göndərişə hazırlanmasına
              cavabdehlik daşıyır. Ən böyük gözləntimiz müştəri məmnuniyyətidir.
            </p>

            <h1 className="font-extrabold text-xl">Çarter xidməti</h1>
            <p>
              "Şuşa Cargo" logistika şirkəti müştərilərimizə özəl sərnişin və
              yük təyyarələrinin, həmçinin vertolyotların kirayalənməsi kimi
              xüsusi xidmət təklif edir. Şirkətimiz xüsusi sifarişlər ilə
              müştərilərimiz üçün kiçik və ya böyük ölçülü sərnişin təyyarəsi
              reysləri təşkil edir, eyni zamanda iri həcmli yüklərin daşınması
              üçün kargo təyyarələri təmin edir. Müştəri sifarişi əsasında mülki
              vertolyotlar kirayələmə edilir. Bütün bunlar və tam təhlükəsizlik
              tədbirlərimiz nəticəsində müştərilərimiz özəl və lüks şəraitə
              sahib olurlar. Hər bir müştəri məmnuniyyəti bizim qürurumuzdur
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
