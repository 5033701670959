import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserProfile, updateUserProfile } from "requests/profile";


const initialState = {
  loading: false,
  user: false,
};

export const getUserProfileInfo = createAsyncThunk(
  "getUserProfileInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUserProfile();
      return response.data;
    } catch (err) {
      
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const updateUserProfileInfo = createAsyncThunk(
  "updateUserProfileInfo",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await updateUserProfile(data);
      dispatch(getUserProfileInfo());
      return response.data;
    } catch (err) {
      
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserProfileInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserProfileInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getUserProfileInfo.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUserProfileInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserProfileInfo.fulfilled, (state, action) => {
      state.loading = false;
      // state.user = action.payload;
    });
    builder.addCase(updateUserProfileInfo.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// export const { setSelectedCustomer } = profile.actions;

export default profile.reducer;
