export const errorStatus = {
  ok: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  methodNotAllowed: 405,
  conflict: 409,
  internalServerError: 500,
  notImplemented: 501,
  badGateway: 502,
  serviceUnavailable: 503,
  gatewayTimeout: 504,
  tooManyRequest: 429,
};

export const warehouseStatus = {
  NOT_ENTERED: 1,
  IN_SENDER_BRANCH_WAREHOUSE: 2,
  IN_RECEIVER_BRANCH_WAREHOUSE: 3,
  DELIVERED_FROM_SENDER_BRANCH_WAREHOUSE: 5,
  DELIVERED_FROM_RECEIVER_BRANCH_WAREHOUSE: 6,
};

export const shippingCondition = {
  IN_SENDER_BRANCH: 1,
  DELIVERED_FROM_SENDER_BRANCH_WAREHOUSE: 2,
  IN_RECEIVER_BRANCH: 3,
  CHECKING_STARTED: 4,
  CHECKING_FINISHED: 5,
};

export let roles = {
  isAdmin: 101,
  isCashier: 102,
  isUser: 103,
};
export const transactionTypes = {
  INCOME: 1,
  EXPENSE: 2,
};
export const branchTypes = {
  SENDER: 1,
  RECEIVER: 2,
};
export const paymentType = {
  cash: 1,
  card: 2,
  heir: 3,
  partial: 4,
};
export const companyPaymentType = {
  heir: 1,
  paymentCash: 2,
  paymentCard: 3,
  // paymentTransfer: 4,
  // paymentOther: 5,
};

export const logSource = {
  sale: 1,
  warehouse: 2,
  insert: 3,
  return: 4,
};
export const saleStatus = {
  SALE: 1,
  RETURN: 2,
};

export const printOptions = {
  PDF: "pdf",
  TERMAL_PRINTER: "terminal",
  WHATSAPP: "whatsapp",
  EMAIL: "email",
};

export const functionkeys = {
  showPrice: "F7",
  saleCash: "F8",
  saleCard: "F9",
  saleHeir: "F10",
  salePartial: "F11",
};

export let numberButtons = [
  {
    label: "5",
    value: 5,
    type: "add",
  },
  {
    label: "10",
    value: 10,
    type: "add",
  },
  {
    label: "20",
    value: 20,
    type: "add",
  },
  {
    label: "50",
    value: 50,
    type: "add",
  },
  {
    label: "100",
    value: 100,
    type: "add",
  },
  {
    label: "200",
    value: 200,
    type: "add",
  },
  {
    label: "+5",
    value: 5,
    type: "increase",
  },
  {
    label: "-5",
    value: 5,
    type: "decrease",
  },
];
