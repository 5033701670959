import axios from "axios";
// import { refreshToken } from "requests/auth";
// import { cookie } from "utils/cookie";

import { errorHandler } from "utils/errorHandler";
import { refreshToken } from "requests/auth";
import { setAccessToken } from "features/auth";
export let store;

export const injectStore = (_store) => {
  store = _store;
};



export const URLAPI =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7072/v1/api/"
    : "https://shushalogistics.com/v1/api/";

const beforeRequest = async (config) => {
  try {
    const res = await refreshToken();

    store.dispatch(setAccessToken(res.data.accessToken));
    config.headers.Authorization ="Bearer " + res.data.accessToken;
  } catch (err) {
    errorHandler(err);
  }
  return config;
};

const afterRespoonse = async (prevRequest) => {
  prevRequest.sent = true;
  let res;
  try {
    res = await refreshToken();

    store.dispatch(setAccessToken(res.data.accessToken));
  } catch (err) {
    errorHandler(err);
  }
  prevRequest.headers.Authorization ="Bearer " + res.data.accessToken;
  return prevRequest;
};

export const auth = axios.create({
  baseURL: `${URLAPI}auth`,
  // headers: {
  //   "Content-Type": "application/json",
  // },
  withCredentials: true,
});

// Add a request interceptor
auth.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // config.headers.Authorization = `Bearer ${cookie("token")}`;
    return config;
  },
  function (error) {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
auth.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
  },
  function (error) {
    console.log(error);
    errorHandler(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const api = axios.create({
  baseURL: URLAPI,
  headers: {
    "Content-Type": "application/json",
    // Authorization: "Token " + cookie("token"),
  },

  // withCredentials: true,
});
api.interceptors.request.use(
  async function (config) {
    console.log(store.getState().auth.accessToken);
        if (!config.headers.Authorization) {
      if (!store.getState().auth.accessToken) {
        config = beforeRequest(config);
      } else {
        config.headers.Authorization = "Bearer " + store.getState().auth.accessToken;
      }
    }
    return config;
  },
  function (error) {
    errorHandler(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    let prevRequest = error?.config;
    if (error?.response?.status === 401) {
      prevRequest = await afterRespoonse(prevRequest);
      return api(prevRequest);
    } else {
      errorHandler(error);
      return Promise.reject(error);
    }
  }
);

export const apiPublic = axios.create({
  baseURL: URLAPI,
  headers: {
    "Content-Type": "application/json",
  },

  // withCredentials: true,
});
// apiPublic.interceptors.request.use(
//   function (config) {
//     // config.headers.Authorization = `Bearer ${cookie("token")}`;

//     return config;
//   },
//   function (error) {
//     // errorHandler(error);
//     return Promise.reject(error);
//   }
// );

// apiPublic.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     errorHandler(error);
//     return Promise.reject(error);
//   }
// );
