import Slider from "react-slick";
import { branchTypes } from "utils/variable";

import heroimg1 from "assets/images/heroSlider/1.jpg";
import heroimg2 from "assets/images/heroSlider/5.jpg";
import heroimg3 from "assets/images/heroSlider/6.jpg";
import heroimg4 from "assets/images/heroSlider/7.jpg";
import heroimg5 from "assets/images/heroSlider/8.jpg";
import heroimg6 from "assets/images/heroSlider/12.jpg";
import heroimg7 from "assets/images/heroSlider/22.jpg";

import img3 from "assets/images/servicesSlider/3.jpg";
import img4 from "assets/images/servicesSlider/4.jpg";
import img5 from "assets/images/servicesSlider/5.jpg";
import img6 from "assets/images/servicesSlider/6.jpg";
import img7 from "assets/images/servicesSlider/7.jpg";
import img8 from "assets/images/servicesSlider/8.jpg";
import { useState } from "react";

import serviceimg1 from "assets/images/services/Anbarxidməti.jpg";
import serviceimg2 from "assets/images/services/Dənizyoludaşımacılığı.webp";
import serviceimg3 from "assets/images/services/Havayoludaşımacılığı.jpg";
import serviceimg4 from "assets/images/services/Quruyoldaşımaxidməti.jpg";
// import { getBranches, getServices } from "../requests/public";
// import { URLAPI } from "apis/axios";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span
        onClick={toggleReadMore}
        className="text-[rgb(192,192,192)] cursor-pointer"
      >
        {isReadMore ? "...daha çox oxu" : "daha az oxu"}
      </span>
    </p>
  );
};

const Services = ({ data }) => {
  console.log(data);
  return (
    <div className={`rounded bg-white   flex flex-col justify-between`}>
      <div className="py-2 text-xl font-bold px-4"> {data?.name}</div>
      <div className="flex flex-col justify-between">
        <address className="italic px-4">
          <ReadMore>{data?.description}</ReadMore>
        </address>

        <div className="">
          <img src={ data?.image} alt="kk" />
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  // const [branchesList, setBranchesList] = useState([]);
  // const [servicesList, setServicesList] = useState([]);

  var settings = {
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    fade: true,
    easing: `cubic-bezier(0.600, -0.280, 0.735, 0.045)`,
    // centerMode: true,
  };
  var settingsService = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
  };

  const branches = [
    {
      id: 1,
      company: "Şuşa Logistics",
      name: "Azərbaycan 🇦🇿",
      address: `Bakı şəhəri Qaradağ Ray. Binə Bazarı Qaradağ Azərbaycan`,
      phone: ["+994 55 509 58 58", "+994 10 519 58 58","+994 50 894 52 23"],
      email: "",
      type: branchTypes.RECEIVER,
    },

    // {
    //   id: 2,
    //   company: "Şuşa logistics",
    //   name: "Türkiyə 🇹🇷",
    //   address:
    //     "İstanbul Aksaray mahallesi küçük langa caddesi Avrupa garajı no 79 A4 Fatih İstanbul",
    //   phone: ["+90 533 703 95 99", "+90 505 009 36 96"],
    //   email: "",
    //   type: branchTypes.SENDER,
    // },
    {
      id: 3,
      company: `Şuşa Logistics Cargo`,
      name: "Türkiye 🇹🇷",
      address: `Aksaray Mah. Namık Kemal Cadş No:68 Akyıldız Otopark No:7/A`,
      phone: ["+90 530 632 67 23", "+90 530 632 68 23"],
      email: "",
      type: branchTypes.SENDER,
    },
    {
      id: 4,
      company: "Şuşa logistics",
      name: "İran 🇮🇷",
      address: "iran.tabriz.kondrod.posht.restoran.helia",
      phone: ["+989140333194"],
      email: "",
      type: branchTypes.SENDER,
    },
    {
      id: 5,
      company: "Shusha logistics",
      name: "Chin 🇨🇳",
      address: `Shenzen, Futian District, Hongli Road, Qunxing Plaza, Bloc A3005. Shusha logistics`,
      phone: ["+86 13828838895"],
      email: "",
      type: branchTypes.SENDER,
    },
    {
      id: 6,
      company: "Shusha LOGİSTİCS",
      name: "Afrika: Sierra Leone  🇸🇱",
      address: `Freetown 
      50 Rowden street `,
      phone: ["+232 34 44 44 48"],
      email: "",
      type: branchTypes.SENDER,
    },
    {
      id: 7,
      company: "Shusha logistics",
      name: "Rusiya 🇷🇺",
      address:
        "Moskva, Berezhkovskiy passage 17, microdistrict Klimovsk, Podolsk, Russian Federation, 142181",
      phone: ["+7 928 542 39 36"],
      email: "",
      type: branchTypes.SENDER,
    },
    {
      id: 8,
      company: 'Shusha lojistics and Q lojitics \n Frankfurt Sirket:Q lojistik and Shusha lojistik AŞ',
      name: "Almanya Frankfurt 🇩🇪",
      address: `Industriestraße 17
      63150 Heusenstamm`,
      phone: ["+49  176 476 292 72"],
      email: "",
      type: branchTypes.SENDER,
    },
  ];

  const images = [
    // {
    //   id: 1,
    //   url: img1,
    // },
    // {
    //   id: 2,
    //   url: img2,
    // },
    {
      id: 3,
      url: img3,
    },
    {
      id: 4,
      url: img4,
    },
    {
      id: 5,
      url: img5,
    },
    {
      id: 6,
      url: img6,
    },
    {
      id: 7,
      url: img7,
    },
    {
      id: 8,
      url: img8,
    },
  ];
  const heroImages = [
    {
      id: 1,
      url: heroimg1,
    },
    {
      id: 2,
      url: heroimg2,
    },
    {
      id: 3,
      url: heroimg3,
    },
    {
      id: 4,
      url: heroimg4,
    },
    {
      id: 5,
      url: heroimg5,
    },
    {
      id: 6,
      url: heroimg6,
    },
    {
      id: 7,
      url: heroimg7,
    },
  ];

  const services = [
    {
      id: 1,
      name: "Anbar xidmətləri.",
      description: `"Şuşa Cargo" logistika şirkəti Türkiye, Rusiya, Çin, İran, Almaniya ve Afrika ölkələrində alış-veriş edən şəxslərə bu ölkələrdə anbar xidməti göstərməklə yanaşı həmçinində bu anbarlara elektron ticarət zamanı qanunla qadağan edilməmiş hər növ bağlama göndərə bilər.`,
      image: serviceimg1,
    },
    {
      id: 2,
      name: "Dəniz daşımaçılığı",
      description:
        "Dəniz daşımaçılığı ən çox üstünlük verilən nəqliyyat növüdür və biz bu sahədədə çəkisindən asılı olmayaraq hər növ yüklərinizi daşıyırıq. Hava və quru yolları daşımaçılığından bir neçə dəfə ucuz olduğu üçün ağır yüklərin daşınmasında da ən sərfəli vasitə olan Dəniz daşımaçılığında yüklərin tam təhlükəsizliyinə və vaxtında çatdırılmasına  təminat veririk.",
      image: serviceimg2,
    },
    {
      id: 3,
      name: "Havayolu kargo xidməti.",
      description: `"Şuşa Cargo" logistika şirkəti olaraq Azərbaycan qanunlarına uyğun olaraq istənilən növ ve çəkidəki yükləri hava yolu ilə dünyanın istənilən ölkəsinə göndərmək və müxtəlif növ baglamaları ofis ve anbarlarımızdan təhvil alıb istənilən ölkəyə ən qısa müddətdə və ən təhlükəsiz şəkildə çatdırırıq. Beləki biz bağlamaların təhkükəsiz daşınmasına tam zəmanət veririk. Bizim başlıca öncəliyimiz müştəri məmnuniyyətini təmin etməkdir.
      Bu xidmətlərimizlə müştərilərimiz ağır yüklü konteynerlərin və hər növ məhsulların daşinmasinda əlavə xərclərdən xilas olacaq və yüklərin təhlükəsizliyinə əmin olacaq. "Şuşa Cargo" müxtəlif yüklərin bir limandan digərinə və ya bir limandan dənizə çıxışı olmayan digər nöqtəyə çatdırılmasını təmin edir.`,
      image: serviceimg3,
    },
    {
      id: 4,
      name: "Quru yol daşıma xidməti",
      description: ``,
      image: serviceimg4,
    },
  ];

  // useLayoutEffect(() => {
  //   getBranches()
  //     .then((res) => {
  //       setBranchesList(res.data.branch);
  //     })
  //     .catch((err) => {
  //       setBranchesList(branches);
  //     });

  //   getServices()
  //     .then((res) => {
  //       setServicesList(res.data.services);
  //     })
  //     .catch((err) => {
  //       setServicesList(services);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <div className="z-10 !min-h-[500px] bg-gray-300 pb-10">
        <div className="hero login-height">
          <Slider {...settings}>
            {heroImages.map((image) => (
              <div key={image.id}>
                <img
                  src={image.url}
                  alt=""
                  className="w-full h-full max-h-[80vh] object-cover"
                />
              </div>
            ))}
          </Slider>
          <div className="m-auto container overflow-hidden ">
            <div className="flex flex-col gap-y-8 mt-16 px-2 xl:px-0">
              <div className="text-center text-2xl font-bold">
                <h1 className="text-2xl md:text-4xl font-bold">
                  Xidmətlərimiz
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4   ">
                {services?.map((item, i) => (
                  <Services data={item} key={i} />
                ))}
              </div>
              <div>
                <Slider {...settingsService}>
                  {images.map((image) => (
                    <div
                      key={image.id}
                      style={{ width: `100%`, height: "100%" }}
                    >
                      <img
                        src={image.url}
                        alt=""
                        className="w-full h-full max-h-[40vh] object-cover"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="flex flex-col gap-y-8 mt-16 px-2 xl:px-0">
              <div className="text-center text-2xl font-bold">
                <h1 className="text-2xl md:text-4xl font-bold">
                  Filiallarimiz
                </h1>
              </div>
              <div>
                <img src={require("assets/images/addressimg.jpg")} alt="" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-8  ">
                {branches?.map((branch, i) => (
                  <div className={`rounded bg-white p-4 `} key={i}>
                    <div className="py-2 text-xl font-bold">{branch?.name}</div>
                    <div className="py-2 text-lg font-bold whitespace-pre">
                      {branch?.company}
                    </div>
                    <address className="italic">{branch?.address}</address>
                    <div>
                      <div>Əlaqə</div>
                      {branch?.phone?.map((phone, i) => (
                        <div key={i}>{phone}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
