import { api } from "apis/axios";

export const getCategory = async (data) => {
  return await api.get(`/categories?search=${data?.search ?? ""}`);
};
export const setCategory = async (data) => {
  return await api.post(`/categories`,data);
};
export const deleteCategory = async (id) => {
  return await api.delete(`/categories/${id}`);
};
export const updateCategory = async ({id,data}) => {
  return await api.put(`/categories/${id}`,data);
};
