import { api } from "apis/axios";

export const getBranches = async (data) => {
  return await api.get(`/branches?search=${data?.search ?? ""}`);
};
export const setBranches = async (data) => {
  return await api.post(`/branches`,data);
};
export const deleteBranches = async (id) => {
  return await api.delete(`/branches/${id}`);
};
export const updateBranches = async ({id,data}) => {
  return await api.put(`/branches/${id}`,data);
};
