import {
  FaFacebook,
  FaInstagram,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";
const Footer = () => {
  const date = new Date();

  const contactList = [
    {
      id: 1,
      icon: <FaWhatsapp />,
      url: "https://wa.me/994555095858",
      title: "+994 55 509 58 58",
      color: "#075e54",
    },
    {
      id: 2,
      icon: <FaWhatsapp />,
      url: "https://wa.me/994105195858",
      title: "+994 10 519 58 58",
      color: "#075e54",
    },
    {
      id: 3,
      icon: <FaFacebook />,
      url: "https://www.facebook.com/shushalogistics",
      title: "Shusha Logistics",
      color: "#1877f2",
    },
    {
      id: 4,
      icon: <FaInstagram />,
      url: "https://www.instagram.com/shushalogistics",
      title: "Shusha Logistics",
      color: "#c13584",
    },
    {
      id: 5,
      icon: <FaTelegram />,
      url: "https://t.me/shushalogistics",
      title: "Shusha Logistics",
      color: "#0088cc",
    },
  ];
  return (
    <div className="py-10 border-t">
      <div className="container mx-auto px-4 ">
        <div className="contact grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-6">
          {/* <div className="contact__item flex flex-col gap-1"> */}
          {contactList.map((item) => {
            return (
              <div
                key={item.id}
                className={`contact__item__content flex items-center gap-2  p-4 rounded-md`}
                style={{ backgroundColor: item.color }}
              >
                <div className="rounded-lg p-4 bg-slate-300">{item.icon} </div>
                <a
                  rel="noreferrer"
                  className="text-white"
                  target={"_blank"}
                  href={item.url}
                >
                  {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center items-center h-full">
        <span className="text-xs md:text-base font-bold">
          Shusha Kargo © {date.getFullYear()} Bütün hüquqlar qorunur by{" "}
          <a href="https://sabitalizade.com" target="_blank" rel="noreferrer">
            Sabit Alizade
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
