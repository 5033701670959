/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import logo from "assets/images/susaLogisticst.png";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="Header container">
      <Link
        to="/"
        className="logo flex items-center pl-4 xl:px-10 "
        onClick={() => setNavVisibility(false)}
      >
        <img src={logo} className="Logo" alt="logo" />
        {/* <div className="font-bold text-2xl text-gray-900">Shusha Kargo</div> */}
      </Link>

      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={250}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <Link
            className="!text-blue-700"
            to="/about"
            onClick={() => setNavVisibility(false)}
          >
            Haqqımızda
          </Link>
          <Link
            className="!text-blue-700"
            to="/prohibited"
            onClick={() => setNavVisibility(false)}
          >
            Qadağan olunan məhsullar
          </Link>
          {/* <Link className="a" to="/about">
            About
          </Link>
          <Link className="a" to="/contact">
            Contact
          </Link> */}
          <Link
            onClick={() => setNavVisibility(false)}
            to={"/tackparcel"}
            className=" flex px-4 py-2 border rounded !text-slate-800 border-slate-800 hover:!text-white hover:bg-slate-800"
          >
            Bağlama izlə
          </Link>
        </nav>
      </CSSTransition>
      <button
        onClick={toggleNav}
        className="Burger flex items-center px-3 py-0 h-16 border rounded text-teal-200 border-teal-400    "
      >
        <svg
          className="fill-current h-6 w-6"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </header>
  );
}
