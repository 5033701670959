import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./Footer";

const StaticLayout = () => {
  return (
    <>
      <div className="w-full bg-white z-50 flex flex-col items-center sticky !border-b !border-x-gray-500">
        <Header />
      </div>

      <Outlet />

      <Footer />
    </>
  );
};

export default StaticLayout;
