import { Form, Input, Button } from "antd";
// import Header from "pages/components/Header";
// import { Link } from "react-router-dom";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
// import ForgetPasswordModal from "pages/components/ForgetPasswordModal";
import { useState } from "react";
import { auth } from "apis/axios";
import { ROLECONTROL } from "utils/parseJwt";
import { roles } from "utils/variable";
import { setAccessToken } from "../features/auth";
import { useDispatch } from "react-redux";

const Login = () => {
  const [logining, setlogining] = useState(false);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginForm = (data) => {
    setlogining(true);
    const form = new FormData();
    form.append("email", data.email);
    localStorage.setItem("email", data.email);
    form.append("password", data.password);

    auth
      .post("/login", form)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAccessToken(res.data.accessToken));
          let expireDate = new Date(Date.now() + 31536000e3);
          expireDate = expireDate.toUTCString();

          // document.cookie = `refreshToken=${res.data.refreshToken}; expires=${expireDate}; path=/`;
          document.cookie = `token=${res.data.accessToken}; expires=${expireDate}; path=/`;
          if (ROLECONTROL(roles.isAdmin) || ROLECONTROL(roles.isCashier)) {
            window.location.assign("/cashregister");
          } else if (ROLECONTROL(roles.isUser)) {
            window.location.assign("/warehouse");
          }
        }
        setlogining(false);
      })
      .finally((err) => {
        setlogining(false);
      });
  };

  return (
    <>
      {/* <div className="!border-b !border-x-gray-500">
        <Header />
      </div> */}
      <div className=" m-auto flex w-full login-height justify-center	items-center ">
        <div className="container flex w-full h-screen  justify-center	items-center">
          <div className="p-11 rounded-lg bg-blue-900">
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={LoginForm}
              autoComplete="on"
            >
              <div className="text-xl p-4 text-center text-white w-ful text-bold">
                Shusha Logistics Admin Panel
              </div>
              <Form.Item
                label={<div className="text-white">Email</div>}
                name="email"
                className="text-white"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  size="large"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                label={<div className="text-white">Password</div>}
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>
              {/* 
        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}
              {/* <div>
                <ForgetPasswordModal />
              </div> */}
              <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                <Button loading={logining} type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              {/* <div className="text-white">
                Already haven`t account?
                <Link to="/register" type="primary" htmlType="submit">
                  Register
                </Link>
              </div> */}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
