import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsers } from "requests/users";

const initialState = {
  loading: false,
  error: "",
  users: [],
  total: 0,
};

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUsers(data);

      return response.data;
    } catch (err) {}
  }
);

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersList.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.users = action.payload.items;
      state.total = action.payload.total;
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.loading = false;
      state.error = "Server not response";
    });
  },
});

export const { toggle } = users.actions;

export default users.reducer;
