import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCompanyrPayment,
  getCompanies,
  getCompanyPayment,
  getSingleCompanies,
} from "requests/companies"; 

const initialState = {
  loading: false,
  paymentStatus: false,
  paymentListStatus: false,
  companies: [],
  company: {},
  payments: [],
};

export const getCompaniesList = createAsyncThunk(
  "getCompaniesList",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCompanies(data);

      return response.data;
    } catch (err) { 
    }
  }
);
export const getCompany = createAsyncThunk(
  "getCompany",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSingleCompanies(data.id);
      return response.data;
    } catch (err) { 
    }
  }
);
export const AddCompanyPayment = createAsyncThunk(
  "AddCompanyPayment",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await createCompanyrPayment(data);
      return response.data;
    } catch (err) { 
    }
  }
);
export const getPayment = createAsyncThunk(
  "getPayment",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCompanyPayment(data);
      return response.data;
    } catch (err) { 
    }
  }
);

export const companies = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompaniesList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompaniesList.fulfilled, (state, action) => {
      state.loading = false;
      state.companies = action.payload.companies;
    });
    builder.addCase(getCompaniesList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload;
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(AddCompanyPayment.pending, (state, action) => {
      state.paymentStatus = true;
    });
    builder.addCase(AddCompanyPayment.fulfilled, (state, action) => {
      state.paymentStatus = false;
    });
    builder.addCase(AddCompanyPayment.rejected, (state, action) => {
      state.paymentStatus = false;
    });
    builder.addCase(getPayment.pending, (state, action) => {
      state.paymentListStatus = true;
    });
    builder.addCase(getPayment.fulfilled, (state, action) => {
      state.paymentListStatus = false;
      state.payments = action.payload;
    });
    builder.addCase(getPayment.rejected, (state, action) => {
      state.paymentListStatus = false;
    });
  },
});

// export const { toggle } = companies.actions;

export default companies.reducer;
