import { api } from "apis/axios";
import { download } from "./download";

export const getParcels = async (data) => {
  return await api.get(
    `/parcels?search=${data?.search ?? ""}&page=${data?.page ?? 1}&limit=${
      data?.limit ?? 10
    }&minWeight=${data?.minWeight ?? ""}&maxWeight=${
      data?.maxWeight ?? ""
    }&customsDuty=${data?.customsDuty ?? ""}`
  );
};
export const setParcels = async (data) => {
  return await api.post(`/parcels`, data);
};
export const deleteParcels = async (id) => {
  return await api.delete(`/parcels/${id}`);
};
export const getSingleParcels = async (id) => {
  return await api.get(`/parcels/${id}`);
};
export const getSingleParcelByCode = async (id) => {
  return await api.get(`/parcels/parcel/code/${id}`);
};

export const updateParcel = async ({ id, data }) => {
  return await api.put(`/parcels/${id}`, data);
};
export const updateCustomsDutyParcel = async ({ id, data }) => {
  return await api.put(`/parcels/customsDuty/${id}`, data);
};
export const removeSack = async ({ id }) => {
  return await api.put(`/parcels/remove/sack/${id}`);
};
// export const updateParcelPayment = async (data) => {
//   return await api.post(`/parcels/pay`, data);
// };
// export const getParcelPayment = async (data) => {
//   return await api.get(`/parcels/payments?parcel=${data.Parcel}`);
// };
export const exportParcels = async (data) => {
  download("/parcels/export", "parcels.xlsx");
};
